import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { parse, stringify } from "qs";

import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import getDealBanner, { DealBanner } from "api/requests/getDealBanner";
import { getDispensary } from "api/requests/getDispensary";
import { getDispensaryDeals } from "api/requests/getDispensaryDeals";
import getMenuItems, { GetMenuItemsReturn } from "api/requests/getMenuItems";
import { DISPENSARY_PAGES } from "constants/dispensary";
import { MenuProvider } from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary, RetailType } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useCanonical from "hooks/useCanonical";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { retailerMenuMetaData } from "lib/dispensaryMetadata";
import { useDispatch } from "redux/hooks";
import {
  COMPLIANCE_RULESET_LOAD,
  ComplianceRuleset,
} from "redux/reducers/complianceRules";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import generateDispensaryRedirect from "utils/generateDispensaryRedirect";
import { getMenuTypeCookie } from "utils/menuTypeUtils";
import { getCountryCodeHeader } from "utils/requestContext";
import useTrackDispensaryPageViewSplitEvent from "utils/split/useTrackDispensaryPageViewSplitEvent";
import { trackUserHistory } from "utils/userHistory";

import DispensaryBreadcrumbs from "components/Dispensary/DispensaryBreadcrumbs";
import DispensaryHeader from "components/Dispensary/DispensaryHeader";
import DispensaryTabs from "components/Dispensary/DispensaryTabs";
import MenuContainer from "components/Dispensary/MenuContainer";
import MenuNotAvailable from "components/Dispensary/MenuNotAvailable";
import MetaTags from "components/MetaTags";

const NearbyStores = dynamic(
  () => import("components/Dispensary/NearbyStores"),
);

type MenuPageProps = {
  complianceRuleset: ComplianceRuleset;
  brandPlacement: Placement | null;
  deals: Deal[];
  dealsBanner: DealBanner | null;
  dispensary: Dispensary;
  menuData: GetMenuItemsReturn;
  staffPicks: MenuItem[];
};

const MenuPage: React.FC<MenuPageProps> = ({
  complianceRuleset,
  brandPlacement,
  deals,
  dealsBanner,
  dispensary,
  menuData,
  staffPicks,
}) => {
  const {
    id,
    coverPhotoUrl,
    featureTier,
    hasMenuItems,
    logoUrl,
    name,
    retailType,
    slug,
    tags,
  } = dispensary;
  const isInfoTier = featureTier === "info";
  const countryCode = useDomainCountryCode();
  const canonicalUrl = useCanonical([]).replace(/\/menu$/, "");
  const dispatch = useDispatch();

  const { description, image, title } = retailerMenuMetaData(
    countryCode,
    coverPhotoUrl,
    logoUrl,
    name,
    retailType as RetailType,
  );

  useEffect(() => {
    if (complianceRuleset) {
      dispatch({
        complianceRuleset,
        type: COMPLIANCE_RULESET_LOAD,
      });
    }
  }, []);

  useEffect(() => {
    trackUserHistory({
      resourceId: id,
      slug,
      type: "dispensary",
    });
  }, [id]);

  useTrackDispensaryPageViewSplitEvent({ page: "menu", tier: featureTier });

  const isDualLicense = isDispensaryDualLicense(tags);

  return (
    <MenuProvider
      isDualLicenseDispensary={isDualLicense}
      initialData={menuData}
      staffPicks={staffPicks}
    >
      <MetaTags
        description={description}
        image={image}
        robots={"noindex,noarchive"}
        shouldShowAlternates={false}
        title={title}
      >
        <link rel="canonical" href={canonicalUrl} />
      </MetaTags>
      <DispensaryHeader dispensary={dispensary} />
      <DispensaryTabs
        activeTab={DISPENSARY_PAGES.menu}
        dispensary={dispensary}
      />
      {!isInfoTier && hasMenuItems ? (
        <MenuContainer
          dispensary={dispensary}
          brandPlacement={brandPlacement}
          deals={deals}
          dealsBanner={dealsBanner}
        />
      ) : (
        <>
          <MenuNotAvailable
            dispensaryFeatureTier={dispensary.featureTier}
            dispensaryId={dispensary.id}
            dispensaryName={dispensary.name}
            dispensaryRetailType={dispensary.retailType}
          />
          {isInfoTier && (
            <NearbyStores
              dispensaryId={dispensary.id}
              locations={dispensary.locations}
              locationPaths={dispensary.locationPaths}
              className="container mt-xl md:mt-[40px] mb-lg"
            />
          )}
        </>
      )}

      <DispensaryBreadcrumbs dispensary={dispensary} />
    </MenuProvider>
  );
};

export const getServerSideProps = createGetServerSideProps<MenuPageProps>(
  async (context) => {
    const { query, req, resolvedUrl } = context;
    const { slug, menu_type, ...remainingQuery } = query;

    const dispensary = await getDispensary(slug as string);

    const domainCountryCode = getCountryCodeHeader(req.headers);

    const redirect = generateDispensaryRedirect(
      domainCountryCode,
      resolvedUrl,
      query,
      dispensary,
    );

    if (redirect) {
      return { redirect };
    }

    // fallback if redirect not generated
    if (!dispensary) {
      return {
        notFound: true,
      };
    }

    const userMedRecPreference = menu_type
      ? Array.isArray(menu_type)
        ? String(menu_type)
        : menu_type
      : getMenuTypeCookie(req);
    const isDualLicenseDispensary = isDispensaryDualLicense(
      dispensary.tags || [],
    );

    const menuItemsCall = getMenuItems(slug as string, {
      ...parse(stringify(remainingQuery)),
      enableNewFilters: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const complianceCall = getRegionComplianceRules(
      dispensary.state,
      dispensary.country,
    );

    const brandPlacementCall = getBrandPlacement(dispensary.slug, {
      menuType: isDualLicenseDispensary ? userMedRecPreference : undefined,
      ...parse(stringify(remainingQuery)),
      strategies: ["lite-amplification", "menu-merchandising"],
    });

    const dealsCall = getDispensaryDeals(dispensary.slug);

    const staffPicksCall = getMenuItems(slug as string, {
      is_staff_pick: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const dealsBannerCall = getDealBanner(slug as string, userMedRecPreference);

    const [
      filteredMenu,
      complianceRuleset,
      brandPlacement,
      { data: deals },
      { menuItems: staffPicks },
      dealsBanner,
    ] = await Promise.all([
      menuItemsCall,
      complianceCall,
      brandPlacementCall,
      dealsCall,
      staffPicksCall,
      dealsBannerCall,
    ]);

    // The getMenuItems call only returns totalItems of that specific query, not the
    // total total items. To avoid showing "menu not available" instead of "no results"
    // we retrieve menu items without any filters
    const menuFallback =
      Object.keys(remainingQuery).length && filteredMenu.totalItems === 0
        ? await getMenuItems(slug as string, {
            menu_type: isDualLicenseDispensary
              ? userMedRecPreference
              : undefined,
            perPage: 0,
          })
        : filteredMenu;

    const menuData = {
      ...filteredMenu,
      totalItems: menuFallback.totalItems,
    };

    return {
      props: {
        brandPlacement,
        complianceRuleset,
        deals,
        dealsBanner,
        dispensary,
        menuData,
        staffPicks,
      },
    };
  },
);

export default MenuPage;
