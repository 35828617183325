import React, { useCallback } from "react";

import useFilters from "hooks/useFilters";

import FilterShortcut from "components/botanic/FilterShortcut";
import { MobileFilterShortcutsType } from "components/FilteredGrid/MobileFilters";

import usePublishFilterClickEvent from "../hooks/usePublishFilterClickEvent";
import {
  MenuFilterGroupTypes,
  MenuFiltersGroup,
  MenuFiltersToggle,
} from "../types/MenuFilters";

const MobileMenuFilterShortcuts: MobileFilterShortcutsType = ({
  onShortcutClick,
  availableFilters,
  menuPage,
  shallowRouting = true,
}) => {
  const { toggleFilter } = useFilters({ shallowRouting });
  const { publishFilterSectionClickEvent } =
    usePublishFilterClickEvent(menuPage);

  const onFilterSectionClick = useCallback(
    ({ name, type }: MenuFiltersGroup | MenuFiltersToggle) => {
      publishFilterSectionClickEvent(name);

      if (type === MenuFilterGroupTypes.toggle) {
        return toggleFilter({ [name]: "true" });
      }

      onShortcutClick(name);
    },
    [onShortcutClick, publishFilterSectionClickEvent, toggleFilter],
  );
  return (
    <>
      {availableFilters.map((filter: MenuFiltersGroup | MenuFiltersToggle) => (
        <MobileMenuFilterShortcut
          key={filter.name}
          text={filter.label as string}
          type={filter.type}
          onClick={() => onFilterSectionClick(filter)}
        />
      ))}
    </>
  );
};

const MobileMenuFilterShortcut: React.FC<{
  onClick: () => void;
  text: string;
  type: MenuFilterGroupTypes;
}> = ({ onClick, text, type }) => {
  const isToggleFilter = type === MenuFilterGroupTypes.toggle;
  return (
    <FilterShortcut
      showChevron={!isToggleFilter}
      text={text}
      onClick={onClick}
      className="!text-default"
    />
  );
};

export default MobileMenuFilterShortcuts;
