import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";

import getDealBanner, { DealBanner } from "api/requests/getDealBanner";
import { useSelector } from "redux/hooks";
import { getUserPrefersMedical } from "redux/selectors/user";
import { MENU_TITLES } from "utils/menuTypeUtils";

import OfferIcon from "components/Icons/deals-tags.svg";

export const DealsBanner: React.FC<{ banner: DealBanner | null }> = ({
  banner,
}) => {
  const {
    query: { menu_type: menuType, slug: dispensarySlug },
  } = useRouter();

  const [dealBanner, setDealBanner] = useState<DealBanner | null>(banner);

  const prefersMedical = useSelector(getUserPrefersMedical);

  const userMedicalPreference = prefersMedical
    ? MENU_TITLES.med.shortTitle
    : MENU_TITLES.rec.shortTitle;

  const currentMenuType =
    (menuType && String(menuType)) || userMedicalPreference;

  const isMounted = useRef(false);
  useEffect(() => {
    if (dispensarySlug && isMounted.current) {
      getDealBanner(dispensarySlug as string, currentMenuType).then(
        (data) => data && setDealBanner(data),
      );
    }

    isMounted.current = true;
  }, [dispensarySlug, currentMenuType]);

  const title = dealBanner?.header;
  const subtitle = dealBanner?.subtitle;

  if (!title) {
    return null;
  }

  return (
    <div
      className="p-md border border-light-grey rounded flex w-full mb-xl"
      data-testid="deals__banner"
    >
      <OfferIcon
        width="20"
        height="20"
        className="flex-shrink-0 text-white bg-notification rounded p-[4px] mx-sm"
      />
      <div className="font-bold text-notification">
        <div data-testid="deal-title">{title}</div>
        {subtitle && (
          <div
            className="text-xs text-grey font-normal"
            data-testid="deal-subtitle"
          >
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};
