import { useCallback, useEffect } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";

import { Action, Category } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";
import isMobileDevice from "utils/isMobileDevice";
import { getSplitKey } from "utils/split/getSplitKey";

import { MenuItemCardPlaceholder } from "components/botanic/MenuItemCard";
import MenuCard from "components/Dispensary/MenuCard";
import ShopNoResults from "components/ShopNoResults";

type Props = {
  hasActiveFilter: boolean;
  menuItems: MenuItem[];
  loading: boolean;
  className?: string;
};

const DispensaryMenuItems: React.FC<Props> = ({
  hasActiveFilter,
  menuItems,
  loading,
}) => {
  const { selectFilterValue, clearAllFilters } = useFilters();
  const { publishEvent } = useEventTracker();

  const dealTitle = selectFilterValue("deal_title");
  const hasActiveDealFilters = dealTitle && dealTitle.length > 0;
  const headerText = hasActiveDealFilters
    ? "Leafly deals"
    : !hasActiveFilter
      ? "All products"
      : null;

  const menuItemsToShow = menuItems || [];

  useEffect(() => {
    if (!menuItemsToShow.length) {
      publishEvent({
        action: Action.impression,
        category: Category.dispensaryMenuFilter,
        label: "Darn! No results found",
      });
    }
  }, [menuItemsToShow]);

  const onAddToBagClick = useCallback(() => {
    sendClientSideSplitTrackEvent(
      {
        eventType: "webDispensaryMenuItem_addToBagClick_frontend",
        properties: { isMobile: isMobileDevice() },
      },
      getSplitKey(),
    );
  }, []);

  if (!menuItemsToShow.length) {
    return hasActiveFilter ? (
      <ShopNoResults
        titleText="No matching results"
        primaryLinkText="reset filters"
        subtitleText="Try selecting fewer options or reset filters"
        action={() => {
          publishEvent({
            action: Action.click,
            category: Category.dispensaryMenuFilter,
            label: "reset all filters",
          });
          clearAllFilters();
        }}
        wrapperClassName="mb-xxl"
      />
    ) : null;
  } else {
    return (
      <>
        {headerText && (
          <h2 className="mb-sm heading--m font-extrabold">{headerText}</h2>
        )}
        {loading && (
          <div className="pb-md flex flex-col gap-md">
            {new Array(menuItemsToShow.length).fill(null).map((_, i) => (
              <MenuItemCardPlaceholder
                className="bg-white shadow-low"
                oneCardPerRow={true}
                key={`placeholder-${i}`}
              />
            ))}
          </div>
        )}

        {!loading && (
          <div className="pb-md flex flex-col gap-md">
            {(menuItemsToShow || []).map((item) => (
              <div data-testid="menu-card" key={item.id}>
                <MenuCard
                  dispensaryId={item.dispensary.id}
                  displayStaffPick={true}
                  menuItem={item}
                  onAddToBagClick={onAddToBagClick}
                  oneCardPerRow={true}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
};

export default DispensaryMenuItems;
