import React from "react";

import { GetMenuItemsAvailableFilters } from "api/requests/getMenuItems";

import {
  MenuFilterMap,
  MenuFiltersGroup,
  MenuFiltersToggle,
  MenuPage,
} from "../types/MenuFilters";
import FilterGroups from "./FilterGroups";

const MenuFilterGroups: React.FC<{
  menuFilterMap?: MenuFilterMap;
  defaultOpenKey: string | undefined;
  availableFilters: GetMenuItemsAvailableFilters;
  menuPage: MenuPage;
  loading?: boolean;
  shallowRouting?: boolean;
}> = ({
  availableFilters,
  menuFilterMap,
  defaultOpenKey,
  menuPage,
  loading,
  shallowRouting,
}) => {
  return (
    <>
      {availableFilters &&
        menuFilterMap &&
        availableFilters.map((filter) => (
          <FilterGroups
            filters={filter as MenuFiltersGroup | MenuFiltersToggle}
            key={filter.name + Math.random().toString()}
            menuFilterMap={menuFilterMap}
            defaultOpenKey={defaultOpenKey}
            menuPage={menuPage}
            loading={loading}
            shallowRouting={shallowRouting}
          />
        ))}
    </>
  );
};

export default MenuFilterGroups;
