import React from "react";
import { Url } from "next/dist/shared/lib/router/router";
import Link from "next/link";

import Button from "components/botanic/Button";
import NotFoundIcon from "components/Icons/not_found.svg";

export type ShopNoResultProps = {
  titleText: string;
  subtitleText?: string;
  primaryLinkText: string;
  action: Url | (() => void);
  wrapperClassName?: string;
  secondaryLink?: {
    text: string;
    href: string;
  };
};

const ShopNoResults: React.FC<ShopNoResultProps> = ({
  titleText,
  subtitleText,
  primaryLinkText,
  action,
  secondaryLink,
  wrapperClassName,
}) => {
  return (
    <div className={wrapperClassName}>
      <div className="border border-light-grey rounded flex flex-col items-center gap-lg p-lg">
        <div className="flex flex-col items-center gap-sm">
          <NotFoundIcon width={48} />
          <h1 className="font-bold text-md text-center">{titleText}</h1>
        </div>
        {subtitleText && <p className="mb-none text-center">{subtitleText}</p>}
        {typeof action === "function" ? (
          <Button
            data-testid="no-results-button"
            variant="secondary"
            onClick={action}
          >
            {primaryLinkText}
          </Button>
        ) : (
          <Link
            className="button button--secondary"
            data-testid="no-results-link"
            href={action}
          >
            {primaryLinkText}
          </Link>
        )}
        {secondaryLink && (
          <Link href={secondaryLink.href}>
            <span className="text-[14px] underline font-bold flex justify-center">
              {secondaryLink.text}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ShopNoResults;
